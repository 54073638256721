import { utagLinkSort } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import doContextualSearch from './doContextualSearch';

const trackContextualSearchEvents = utagLinkSort();
const isViewOnSaleItemsPage = !!(window.location.pathname.toLowerCase() === '/products/onsale_view-on-sale-items/');

const initContextualSearch = (searchInputSelector, searchBtnSelector) => {

	const searchInput = document.querySelector(searchInputSelector);
	const searchBtn = document.querySelector(searchBtnSelector);
	const pageCategory = window.utag_data.product_category[0]
		? window.utag_data.product_category[0].toLowerCase()
		: 'site';
	const searchSite = document.querySelector('#searchSite');
	if (!searchInput) {

		return;

	}

	if (searchSite) {

		if (isViewOnSaleItemsPage) {

			searchSite.innerText = 'Search the sale:';

		} else {

			searchSite.innerText = `Search our ${pageCategory}:`;

		}

	} else {

		if (isViewOnSaleItemsPage) {

			searchInput.placeholder = 'Search the sale';

		} else {

			searchInput.placeholder = `Search our ${pageCategory}`;

		}

	}

	const searchHandler = (e) => {

		e.preventDefault();

		const value = searchInput.value.trim();

		if (value === '') {

			return;

		}
		trackContextualSearchEvents({
			eventAction: `Bottom-${pageCategory}-Search-Submit`,
			eventLabel: searchInput.value.trim().toLowerCase()
		});
		setGenericEvent({
			event_name: 'search',
			search_keyword: window.utag_data?.search_keyword ?? undefined,
			search_provider: window.utag_data?.search_provider ?? undefined,
			search_results: window.utag_data?.search_results ?? '0',
			search_location: 'sort bottom'
		});
		doContextualSearch(searchInput.value.trim());

	};

	searchBtn.addEventListener('click', (e) => {

		searchHandler(e);

	});

	searchInput.addEventListener('keydown', (e) => {

		if (e.key === 'Enter') {

			searchHandler(e);

		}

	});

};

export default initContextualSearch;
