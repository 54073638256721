/**
 * Primary Attributes on sort pages
 */
const primaryAttributesMapping = {
	primaryCategory: 'product_primary_category',
	primaryColor: 'product_primary_color',
	primaryFinish: 'product_primary_finish',
	primaryType: 'product_primary_type',
	primaryStyle: 'product_primary_style',
	skuInputType: 'sku_input_type',
	productIsLpproduct: 'product_is_lpproduct',
	productIsLpNewProduct: 'product_islpnewproduct',
	productGrossMargin: 'product_gross_margin',
	isSpecialValue: 'is_special_value'
};

export default primaryAttributesMapping;
