import { utagLinkSort } from 'features/tealium/';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import doSaleSearch from './doSaleSearch';

const initSaleSearch = (searchInputSelector, searchBtnSelector) => {

	const trackSearchBoxEvents = utagLinkSort();
	const searchInput = document.querySelector(searchInputSelector);
	const searchBtn = document.querySelector(searchBtnSelector);
	let eventCalled = false;

	if (!searchInput) {

		return;

	}

	const searchHandler = (e) => {

		e.preventDefault();

		const value = searchInput.value.trim();

		if (value === '') {

			return;

		}
		if (!eventCalled) {

			trackSearchBoxEvents({
				eventAction: 'Sale-Search-Submit',
				eventLabel: value.toLowerCase()
			});
			setGenericEvent({
				event_name: 'search',
				search_keyword: window.utag_data?.search_keyword ?? undefined,
				search_provider: window.utag_data?.search_provider ?? undefined,
				search_results: window.utag_data?.search_results ?? '0',
				search_location: 'sale'
			});

			eventCalled = true;

		}
		doSaleSearch(searchInput.value.trim());

	};

	searchBtn.addEventListener('click', (e) => {

		searchHandler(e);

	});

	searchInput.addEventListener('keydown', (e) => {

		if (e.key === 'Enter') {

			searchHandler(e);

		}

	});

};

export default initSaleSearch;
