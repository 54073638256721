import { utagLinkOpenBox } from 'features/tealium';
import { decodeUrlSearchTerm } from 'features/sort';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import doOpenBoxSearch from './doOpenBoxSearch';

/**
 * Initializes Open Box search box functionality
 * @param {string} searchInputSelector
 * @param {string} searchBtnSelector
 */
const initOpenBoxSearch = (searchInputSelector, searchBtnSelector) => {

	const trackOpenBoxEvents = utagLinkOpenBox();
	const searchInput = document.querySelector(searchInputSelector);
	const searchBtn = document.querySelector(searchBtnSelector);
	let eventCalled = false;

	const isSearchResultPage = window.location.href.indexOf('/s_') >= 0;
	if (isSearchResultPage) {

		const searchPath = document.location.pathname.split('/s_')[1];
		if (searchPath) {

			const searchTerm = searchPath && decodeUrlSearchTerm(searchPath.split('/')[0]);
			const isMobile = window.utag_data && window.utag_data.is_mobile === '1';
			if (isMobile) {

				const globalSearchField = document.getElementById('globalSearchField');
				globalSearchField.value = searchTerm ? decodeURIComponent(searchTerm) : searchTerm;

			}
			if (searchInput) searchInput.value = searchTerm;

		}

	}

	if (!searchInput) {

		return;

	}

	const searchHandler = (e) => {

		e.preventDefault();

		const value = searchInput.value.trim();

		if (value === '') {

			return;

		}

		if (!eventCalled) {

			trackOpenBoxEvents({
				eventAction: 'Search-Submit',
				eventLabel: value.toLowerCase()
			});
			setGenericEvent({
				event_name: 'search',
				search_keyword: window.utag_data?.search_keyword ?? undefined,
				search_provider: window.utag_data?.search_provider ?? undefined,
				search_results: window.utag_data?.search_results ?? '0',
				search_location: 'open box'
			});

			eventCalled = true;

		}

		doOpenBoxSearch(searchInput.value.trim());

	};

	searchBtn.addEventListener('click', searchHandler);
	searchInput.addEventListener('keydown', (e) => {

		if (e.key === 'Enter') {

			searchHandler(e);

		}

	});

};

export default initOpenBoxSearch;
