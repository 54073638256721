import { debounce } from 'lodash-es';

import getPathName from 'features/tealium/getPathName';

const debounceTime = 500;

const STATUS_PENDING = 'pending';
const STATUS_SENDING = 'sending';
const STATUS_TRACKED = 'tracked';
const STATUS_FAILED = 'failed';

const eventStructure = {
	product_sku: '',
	product_name: '',
	product_price: '',
	product_index: '',
	product_category: '',
	product_quantity: '',
	sku_input_type: ''
};
const eventStructureKeys = Object.keys(eventStructure);

let uniqueId = -1;
function autoId () {

	uniqueId += 1;
	return uniqueId;

}

class ProductImpressionsReporter {

	// eslint-disable-next-line camelcase
	constructor ({ product_grouping }) {

		this.pageViewId = autoId();
		this.pageViewHref = document.location.href;
		// eslint-disable-next-line camelcase
		this.product_grouping = product_grouping;
		this.records = {};

		this.debouncedSend = debounce(() => {

			// Does the browser support requestIdleCallback
			if ('requestIdleCallback' in window) {

				return window.requestIdleCallback(this.send);

			}
			this.send();

		}, debounceTime);

		this.track = this.track.bind(this);
		this.send = this.send.bind(this);

		this.getRecordsByStatus = this.getRecordsByStatus.bind(this);

	}

	// Register page navigation (after navigation, such as in React Router)
	registerPageNavigation () {

		// We have to send because event_label relies on document.location.href which changes
		this.send();

		this.pageViewId = autoId();
		this.pageViewHref = document.location.href;

	}

	flushOldPageRecords () {

		const currentPageViewId = this.pageViewId;
		// Avoid deleting pending records from previous page view by calling send
		this.send().then(() => {

			// All records have been sent, safe to cleanup
			const matchCurrentPageViewId = new RegExp(`^${currentPageViewId}:`);
			const oldRecords = Object.keys(this.records).filter((record) => !matchCurrentPageViewId.test(record));
			oldRecords.forEach((recordName) => {

				delete this.records[recordName];

			});

		});

	}

	getPageViewId () {

		return this.pageViewId;

	}

	getProductImpressionDataFromElement (element) {

		const { dataset } = element;
		const id = [ this.pageViewId, dataset.sku ].join(':');

		const data = {
			id,
			...eventStructure,
			product_sku: dataset.sku,
			product_name: dataset.name || element.title || element.alt,
			product_price: dataset.price,
			product_index: dataset.position,
			product_category: dataset.primaryCategory,
			sku_input_type: dataset.skuInputType,
			product_quantity: 1
		};

		return data;

	}

	// Called in the callback when the element qualifies
	track (data, options = { immediate: false }) {

		const { id } = data;

		if (this.records[id]) {

			console.warn(`Product Impressions: track call ignored for id ${id}; Already fired once.`);
			return Promise.resolve();

		}

		// Deferred promise
		let resolvePromise;
		let rejectPromise;
		const promise = new Promise((resolve, reject) => {

			resolvePromise = resolve;
			rejectPromise = reject;

		});

		this.records[id] = {
			data,
			status: STATUS_PENDING,
			promise,
			resolve: resolvePromise,
			reject: rejectPromise
		};

		if (options.immediate) {

			this.send();

		} else {

			this.debouncedSend();

		}

		return promise;

	}

	static makeEventPayload (record) {

		// Destructure out properties that are not part of the payload, or need special handling
		const { id, ...properties } = record.data; // eslint-disable-line camelcase
		const payload = {
			...eventStructure,
			...properties
		};
		return payload;

	}

	// reduce each event object property into ordinal arrays for the payload
	makeRequestPayload (records) {

		const productGrouping = [];
		const itemListName = [];
		const pathName = getPathName();

		records.forEach(() => {

			productGrouping.push(this.product_grouping[0]);
			itemListName.push(pathName);

		});

		const payload = {
			event_name: 'view_item_list',
			event_noninteraction: 'true',
			gua_non_interaction: true,
			product_grouping: productGrouping,
			item_list_name: itemListName,
			item_list_id: [],
			product_sku: [],
			product_name: [],
			product_price: [],
			product_index: [],
			product_category: [],
			product_quantity: [],

			sku_input_type: [],
			product_sale_type: []
		};

		records.reduce((memo, record) => {

			// Only push properties declared for the event structure
			eventStructureKeys.forEach((key) => {

				memo[key].push(record[key]);

			});

			return memo;

		}, payload);

		return payload;

	}

	send () {

		const records = this.getRecordsByStatus(STATUS_PENDING);

		if (records.length === 0 || !window.utag) {

			return Promise.resolve({});

		}

		// Get payload data
		const events = records.map(ProductImpressionsReporter.makeEventPayload);
		const data = this.makeRequestPayload(events);

		// Update status of records to track as in progress
		ProductImpressionsReporter.updateRecordsStatus(records, STATUS_SENDING);

		const request = new Promise((resolve) => {

			const wasSuccessful = window.utag.link(data, () => resolve('success'));
			if (wasSuccessful) {

				resolve('success');

			}

			// Tracking is allowed 500ms for successful resolution,
			// then we redirect to the cart regardless.
			setTimeout(() => resolve('success'), 500);

		});

		request
			.then(() => {

				// Update status of records to track as tracked
				ProductImpressionsReporter.updateRecordsStatus(records, STATUS_TRACKED);

			})
			.catch(() => {

				ProductImpressionsReporter.updateRecordsStatus(records, STATUS_FAILED);

			});

		return request;

	}

	getRecordById (id) {

		return this.records[id];

	}

	getRecordsByStatus (status) {

		return Object.keys(this.records).reduce((memo, item) => {

			const record = this.records[item];
			if (record.status === status) {

				memo.push(record);

			}
			return memo;

		}, []);

	}

	static updateRecordsStatus (records, status) {

		records.forEach((record) => {

			record.status = status; // eslint-disable-line no-param-reassign

			if (status === STATUS_TRACKED) {

				record.resolve();

			} else if (status === STATUS_FAILED) {

				record.reject();

			}

		});

	}

}

export default ProductImpressionsReporter;
