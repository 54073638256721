import { delegateEvent } from 'utilities/dom';
import decodeHtml from 'utilities/decodeHtml';

import getPathName from './getPathName';
import { getPrimaryAttributesFromDataset } from './utils';

function setProductClick (listType, sortResultContainers, isDelegatedEvent) {

	const isMobile = window.utag_data.is_mobile > 0;
	const trackingData = {
		event_action: 'product click',
		event_category: 'ecommerce',
		event_label: '',
		event_name: 'product_click',
		product_category: [],
		product_impression_list: getPathName(),
		product_action_list: getPathName(),
		product_impression_position: [],
		product_name: [],
		product_price: [],
		product_sku: [],

		product_primary_category: [],
		product_primary_color: [],
		product_primary_finish: [],
		product_primary_type: [],
		product_primary_style: [],

		sku_input_type: [],
		product_islpproduct: [],
		product_islpnewproduct: [],
		product_gross_margin: [],
		filter_id: window.utag_data.filter_id,
		test_id: window.utag_data.test_id,
		testcomposition_id: window.utag_data.testcomposition_id,
		mm_id: window.utag_data.mm_id,
		formula_id: window.utag_data.formula_id,
		pin_id: window.utag_data.pin_id,
		test_start_date: window.utag_data.test_start_date,
		list_type: listType,
		product_grouping: window.utag_data.product_grouping,
		is_special_value: []
	};
	let selectors = [];

	const getProductInfoFromEvent = function (event) {

		let eventTargetElement;

		if (event.target) {

			eventTargetElement = event.target;

			while (eventTargetElement) {

				const dataEl = eventTargetElement.querySelector(
					'img.unveil, img.jsImgShopByRoom, img.sortResultProdImg, .wishlistItemImage'
				);
				if (dataEl) {

					const productInfo = getPrimaryAttributesFromDataset(dataEl.dataset);

					const productName = dataEl.title ? dataEl.title : dataEl.alt;
					productName && (productInfo.productName = productName);
					dataEl.dataset.sku && (productInfo.productSku = dataEl.dataset.sku);
					dataEl.dataset.price && (productInfo.productPrice = dataEl.dataset.price);
					dataEl.dataset.position && (productInfo.productPosition = dataEl.dataset.position);

					return productInfo;

				}

				eventTargetElement = eventTargetElement.parentElement;

			}

		}

		return {};

	};

	const setTrackingData = (e) => {

		const productInfo = getProductInfoFromEvent(e);
		if (productInfo.productName && productInfo.productSku) {

			// Sometimes alt will contain encoded html entities
			const decodedName = decodeHtml(productInfo.productName);

			trackingData.product_sku.push(productInfo.productSku);
			trackingData.product_name.push(decodedName);
			trackingData.event_label = `${productInfo.productSku}-${decodedName}`;
			trackingData.product_impression_position.push(productInfo.productPosition);
			trackingData.product_category.push(productInfo.primaryCategory);

			trackingData.product_primary_category.push(productInfo.primaryCategory);
			trackingData.product_primary_color.push(productInfo.primaryColor);
			trackingData.product_primary_finish.push(productInfo.primaryFinish);
			trackingData.product_primary_type.push(productInfo.primaryType);
			trackingData.product_primary_style.push(productInfo.primaryStyle);

		}

		if (productInfo.productPrice) {

			trackingData.product_price.push(productInfo.productPrice);

		}

		if (productInfo.skuInputType) {

			trackingData.sku_input_type.push(productInfo.skuInputType);

		}
		if (productInfo.productIsLpproduct) {

			trackingData.product_islpproduct.push(productInfo.productIsLpproduct);

		}
		if (productInfo.productIsLpNewProduct) {

			trackingData.product_islpnewproduct.push(productInfo.productIsLpNewProduct);

		}

		if (productInfo.productGrossMargin) {

			trackingData.product_gross_margin.push(productInfo.productGrossMargin);

		}

		if (productInfo.isSpecialValue) {

			trackingData.is_special_value.push(productInfo.isSpecialValue);

		}

		if (window.utag && window.utag_data) {

			window.utag.link(trackingData);

		}

	};

	const addTrackingToElement = function (el) {

		if (!el.dataset.hasTracking) {

			el.addEventListener('click', setTrackingData);
			el.dataset.hasTracking = 'true';

		}

	};

	const addDelegatedTrackingBySelector = (selector) => {

		document.body.addEventListener('click', delegateEvent(setTrackingData, selector));

	};

	if (sortResultContainers) {

		sortResultContainers.forEach((el) => {

			addTrackingToElement(el);

		});

	} else if (isMobile) {

		selectors = [
			'.sortResultInnerContainer > a',
			'.wishlistItemName',
			'.shopByRoomDetailProducts .jsImgShopByRoom[data-sku]'
		];

	} else {

		selectors = [
			'.sortResultContainer .sortResultProdName',
			'.sortResultContainer .sortResultImgContainer',
			'.wishlistItemName',
			'.shopByRoomDetailProducts .jsImgShopByRoom[data-sku]'
		];

	}

	selectors.forEach((sel) => {

		if (isDelegatedEvent) {

			addDelegatedTrackingBySelector(sel);

		} else {

			const els = document.querySelectorAll(sel);
			if (els.length > 0) {

				els.forEach((el) => {

					addTrackingToElement(el);

				});

			}

		}

	});

}

export default setProductClick;
