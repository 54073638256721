import { debounce } from 'lodash-es';

function stickyClassHandler (element, threshold, offset) {

	if (window.pageYOffset > threshold) {

		element.classList.add('stickyFilter');
		document.body.style.marginTop = `${offset}px`;

	} else {

		element.classList.remove('stickyFilter');
		document.body.style.marginTop = 0;

	}

}

function initStickyAttributeMenu () {

	let attributeWrapper;

	if (window.utag_data && window.utag_data.page_type) {

		const pageType = window.utag_data.page_type;

		if (pageType == 'searchresults') {

			attributeWrapper = document.querySelector('.searchContainer');

		} else {

			attributeWrapper = document.querySelector('.setDT-1.stickyEnabled');

		}

	}

	if (!attributeWrapper) {

		return;

	}

	// Timeout allows us to make sure analytics are captured for product image impressions.
	window.setTimeout(() => {

		const bodyMargin = attributeWrapper.offsetHeight;
		const heightBuffer = bodyMargin * 4;
		const attributeWrapperOffsetY = attributeWrapper.offsetTop + heightBuffer;

		stickyClassHandler(attributeWrapper, attributeWrapperOffsetY, bodyMargin);

		window.addEventListener(
			'scroll',
			debounce(() => {

				stickyClassHandler(attributeWrapper, attributeWrapperOffsetY, bodyMargin);

			}, 10)
		);

	}, 1000);

}

export default initStickyAttributeMenu;
