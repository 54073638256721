import AppProductPriceLegalNotice from 'apps/productPriceLegalNotice/desktop';

const isToolTipVisible = ($toolTip) => parseInt($toolTip.css('opacity'), 10) === 1;

const closeVisibleToolTip = () => {

	$('body .lpTooltip').filter((i, el) => isToolTipVisible($(el))).trigger('forceClose.lpTooltip');

};

function initFreeShippingTooltip () {

	const shippingElements = document.querySelectorAll('.sortSplashRightMssgLong, .sortSplashRightMssg, .sortSplashRightMssgXLong, .sortSplashRightMssgShort, .sortSplashRightMssgXSmall, .sortSplashRightMssgXXLong');

	if (shippingElements.length > 0) {

		const freeReturnEle = shippingElements[0];

		const strongElement = freeReturnEle.querySelector('strong');

		if (strongElement) {

			const freeShippingContent = strongElement.innerHTML;

			const newShippingHTML = document.createElement('div');
			newShippingHTML.classList.add('freeShippingSplashTooltip');

			const innerHTML = `
						<div class="freeShippingTooltip">
							<button class="freeShippingTooltip_trigger anchorLink">
								<strong>${freeShippingContent}</strong>
							</button>
							<div class="freeShippingTooltip__content hidden">
								<div class="lpTooltip__inner">
									Free Shipping requires a minimum purchase of $49. 
									Free Shipping is not available for Open Box items. 
									Free Returns only available for outdoor lighting and ceiling fans. 
									No other items eligible for free returns. 
									These shipping terms are only valid for items using standard shipping 
									to the 48 US contiguous states &ndash; Alaska and Hawaii excluded.
									<a href="/help-and-policies/shipping-and-delivery/">View full policy.</a>
								</div>
							</div>
						</div>`;

			newShippingHTML.innerHTML = innerHTML;
			freeReturnEle.replaceChild(newShippingHTML, strongElement);

		}

		const freeShippingSplashTooltip = document.querySelector('.freeShippingSplashTooltip');
		if (freeShippingSplashTooltip) {

			const tooltipTrigger = freeShippingSplashTooltip.querySelector('.freeShippingTooltip_trigger');
			if (tooltipTrigger) {

				AppProductPriceLegalNotice(freeShippingSplashTooltip, tooltipTrigger, { evtType: 'click' },
					'.freeShippingTooltip',	'.freeShippingTooltip__content');

			}

		}

	}

	const container = document.querySelectorAll('.freeReturnsContainer');

	if (container) {

		container.forEach((el) => {

			if (el.innerText !== '') {

				const tooltipTrigger = el.querySelector('.freeShippingTooltip_trigger');
				AppProductPriceLegalNotice(el, tooltipTrigger, { evtType: 'click' }, '.freeShippingTooltip',
					'.freeShippingTooltip__content');
				tooltipTrigger.addEventListener('click', closeVisibleToolTip);

			}

		});

	}


}

export default initFreeShippingTooltip;
